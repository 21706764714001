import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Trans, translate } from 'react-i18next';
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CardTitle,
  Col,
  Row
} from 'reactstrap';
import get from 'lodash/get';
import { save } from '../../../helpers/actions/projects';
import { clientName } from '../../../helpers/formatters';
import ClientsListModal from './ClientsListModal';
import defaultAvatar from '../../../assets/img/default-avatar.png';
import { isMobileOnly } from 'react-device-detect';

class AssignClient extends Component {
  constructor(props) {
    super(props);
    this.state = {
      project: {},
      assignClient: false
    };
  }

  componentWillReceiveProps(nextProps, prevState) {
    this.setState({ project: nextProps.project });
  }

  componentWillMount() {
    let { project } = this.props;
    this.setState({ project });
  }

  assignClient(newClient) {
    let { dispatch } = this.props,
      { project } = this.state;

    dispatch(save({ _id: project._id, client: newClient }))
      .then(() => this.props.reload())
      .catch(() => {});
  }

  render() {
    let { project, assignClient } = this.state;

    return [
      isMobileOnly ? (
        <Row key={'assignClient'} className="user-data">
          <Col xs={12}>
            <div
              className="project-detail-avatar"
              style={{
                backgroundImage: `url('${get(
                  project,
                  'client.photoURL',
                  defaultAvatar
                )}')`
              }}
            />
          </Col>
          <Col xs={12}>
            <CardTitle tag="h5" className={'text-center'}>
              {clientName(project.client)}
            </CardTitle>
          </Col>
          <Col xs={12}>
            {project.client ? (
              <div>
                <ul>
                  <li>
                    <strong className={'mr-1'}>
                      <Trans>Email</Trans>:
                    </strong>
                    {get(project, 'client.email', '') ? (
                      <a
                        href={`mailto:${get(
                          project,
                          'client.email',
                          ''
                        )}?Subject=Placeshaker`}
                        target="_top"
                      >
                        {get(project, 'client.email', '')}
                      </a>
                    ) : null}
                  </li>
                  <li>
                    <strong className={'mr-1'}>
                      <Trans>Address</Trans>:
                    </strong>
                    {get(project, 'client.lat', null) &&
                    get(project, 'client.lng', null) ? (
                      <a
                        href={`https://www.waze.com/ul?ll=${project.client.lat}%2C${project.client.lng}&zoom=17`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {get(project, 'client.address', '')}
                      </a>
                    ) : (
                      get(project, 'client.address', '')
                    )}
                  </li>
                  <li>
                    <strong className={'mr-1'}>
                      <Trans>Mobile</Trans>:
                    </strong>
                    <a href={`tel:${get(project, 'client.phone.mobile', '')}`}>
                      {get(project, 'client.phone.mobile', '')}
                    </a>
                  </li>
                </ul>
              </div>
            ) : (
              <Row>
                <Col xs={12} sm={12} md={12} lg={12}>
                  <p className="text-center">
                    <Trans>Assign this project to a client</Trans>
                  </p>
                </Col>
              </Row>
            )}
          </Col>
          <Col xs={12} className={'text-center pt-0'}>
            {project.type && project.type === 'compound-son' ? null : (
              <Button
                color="info"
                size="sm"
                onClick={() => this.setState({ assignClient: true })}
              >
                <Trans>Assign</Trans>
              </Button>
            )}
          </Col>
        </Row>
      ) : (
        <Card key={'assignClient'} className="card-flex user-data">
          <CardHeader className={'text-center'}>
            <h6>
              <Trans>Client</Trans>
            </h6>
          </CardHeader>
          <CardBody>
            <div
              className="project-detail-avatar"
              style={{
                backgroundImage: `url('${get(
                  project,
                  'client.photoURL',
                  defaultAvatar
                )}')`
              }}
            />
            <CardTitle tag="h5" className={'text-center'}>
              {clientName(project.client)}
            </CardTitle>
            {project.client ? (
              <div>
                <ul>
                  <li>
                    <strong className={'mr-1'}>
                      <Trans>Email</Trans>:
                    </strong>
                    {get(project, 'client.email', '') ? (
                      <a
                        href={`mailto:${get(
                          project,
                          'client.email',
                          ''
                        )}?Subject=Placeshaker`}
                        target="_top"
                      >
                        {get(project, 'client.email', '')}
                      </a>
                    ) : null}
                  </li>
                  <li>
                    <strong className={'mr-1'}>
                      <Trans>Address</Trans>:
                    </strong>
                    {get(project, 'client.lat', null) &&
                    get(project, 'client.lng', null) ? (
                      <a
                        href={`https://www.google.com/maps/place/${get(
                          project,
                          'client.address',
                          ''
                        )}/@=${project.client.lat},${project.client.lng}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {get(project, 'client.address', '')}
                      </a>
                    ) : (
                      get(project, 'client.address', '')
                    )}
                  </li>
                  <li>
                    <strong className={'mr-1'}>
                      <Trans>Mobile</Trans>:
                    </strong>
                    <a href={`tel:${get(project, 'client.phone.mobile', '')}`}>
                      {get(project, 'client.phone.mobile', '')}
                    </a>
                  </li>
                </ul>
              </div>
            ) : (
              <Row>
                <Col xs={12} sm={12} md={12} lg={12}>
                  <p className="text-center">
                    <Trans>Assign this project to a client</Trans>
                  </p>
                </Col>
              </Row>
            )}
          </CardBody>
          <CardFooter className={'text-center pt-0'}>
            {project.type && project.type === 'compound-son' ? null : (
              <Button
                color="info"
                size="sm"
                onClick={() => this.setState({ assignClient: true })}
              >
                <Trans>Assign</Trans>
              </Button>
            )}
          </CardFooter>
        </Card>
      ),
      assignClient ? (
        <ClientsListModal
          key={'clientsListModal'}
          show={assignClient}
          client={project.client}
          dispatch={this.props.dispatch}
          onCancel={() => this.setState({ assignClient: false })}
          assignClient={newClient =>
            this.setState({ assignClient: false }, () =>
              this.assignClient(newClient)
            )
          }
        />
      ) : null
    ];
  }
}

export default connect()(translate('translations-fr')(AssignClient));
