import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Trans, translate } from 'react-i18next';
import {
  Col,
  Modal,
  ModalBody,
  ModalHeader,
  FormGroup,
  Label,
  Row
} from 'reactstrap';
import { Spinner } from '../../../components';
import { paymentPdfBill } from '../../../helpers/actions/projects';
import Switch from 'react-bootstrap-switch';
import Select from 'react-select';

class PlanModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      payment: null,
      loading: true,
      ttc: true,
      params: null,
      receiver: '',
      transmitter: '',
      selectReceiver: [],
      selectTransmitter: []
    };
  }

  componentDidMount() {
    this.loadPdf(true);
  }

  loadPdf() {
    this.setState(ps => ({ ...ps, loading: true }));
    let { payment, dispatch } = this.props;

    let psOptions = ['pr', 'psdb', 'pc'];
    let selectReceiver = null;
    let selectTransmitter = null;
    let receiver = this.state.receiver || null;
    let transmitter = this.state.transmitter || null;

    if (payment.entity === 'provider') {
      if (payment.type === 'debit') {
        selectTransmitter = psOptions;
        selectReceiver = ['provider'];
        transmitter = transmitter || psOptions[0];
        receiver = receiver || 'provider';
      } else {
        selectTransmitter = ['provider'];
        selectReceiver = psOptions;
        transmitter = transmitter || 'provider';
        receiver = receiver || psOptions[0];
      }
    }

    if (payment.entity === 'client') {
      if (payment.type === 'debit') {
        selectTransmitter = psOptions;
        selectReceiver = ['client'];
        transmitter = transmitter || psOptions[0];
        receiver = receiver || 'client';
      } else {
        selectTransmitter = ['client'];
        selectReceiver = psOptions;
        transmitter = transmitter || 'client';
        receiver = receiver || psOptions[0];
      }
    }

    if (payment.entity === 'pro') {
      if (payment.type === 'debit') {
        selectTransmitter = psOptions;
        selectReceiver = ['pro'];
        transmitter = transmitter || psOptions[0];
        receiver = receiver || 'pro';
      } else {
        selectTransmitter = ['placeproshaker'];
        selectReceiver = psOptions;
        transmitter = transmitter || 'pro';
        receiver = receiver || psOptions[0];
      }
    }

    if (payment.entity === 'placeshaker') {
      if (payment.type === 'debit') {
        selectTransmitter = ['provider', 'client'];
        selectReceiver = psOptions;
        transmitter = transmitter || 'provider';
        receiver = receiver || psOptions[0];
      } else {
        selectTransmitter = psOptions;
        selectReceiver = ['provider', 'client'];
        transmitter = transmitter || psOptions[0];
        receiver = receiver || 'provider';
      }
    }

    dispatch(paymentPdfBill(this.props.project, payment._id, {}))
      .then(params => {
        this.setState(ps => ({
          ...ps,
          params,
          payment,
          selectTransmitter,
          selectReceiver,
          transmitter,
          receiver
        }));
      })
      .catch(err => {
        console.log(err);
      });
  }

  setFilter(key, data) {
    this.setState(
      ps => ({
        ...ps,
        [key]: data
      }),
      () => this.loadPdf()
    );
  }

  render() {
    let {
      loading,
      params,
      payment,
      ttc,
      receiver,
      transmitter,
      selectReceiver,
      selectTransmitter
    } = this.state;
    let { t } = this.props;

    let url = null,
      filename = `${t('Bill')}`;
    if (params) {
      url =
        params.url +
        'entity=' +
        payment.entity +
        '&ttc=' +
        ttc +
        '&receiver=' +
        receiver +
        '&transmitter=' +
        transmitter +
        '&apiRole=admin' +
        '&apiKey=' +
        params.httpHeaders.Authorization.replace('Bearer ', '');
    }

    return (
      <Modal
        size="lg"
        isOpen={!!this.props.payment}
        toggle={() => this.props.onClose()}
        className={'resize-modals'}
      >
        {loading ? <Spinner /> : null}
        <ModalHeader
          className="justify-content-center"
          toggle={() => this.props.onClose()}
        >
          <Trans>Payment Bill</Trans>
        </ModalHeader>
        <ModalBody className={'pt-2'}>
          <Row className={'font-weight-bold switch-bill-pdf'}>
            <Col>
              <FormGroup>
                <Label>
                  <Trans>transmitter</Trans>
                </Label>
                <Select
                  disabled={selectTransmitter.length <= 1}
                  isSearchable={false}
                  clearable={false}
                  className="primary"
                  options={selectTransmitter.map(pt => ({
                    label: t(pt),
                    value: pt
                  }))}
                  value={this.state.transmitter}
                  onChange={event =>
                    this.setFilter('transmitter', event ? event.value : null)
                  }
                />
              </FormGroup>
            </Col>

            <Col>
              <FormGroup>
                <Label>
                  <Trans>receiver</Trans>
                </Label>
                <Select
                  disabled={selectReceiver.length <= 1}
                  isSearchable={false}
                  clearable={false}
                  className="primary"
                  options={selectReceiver.map(pt => ({
                    label: t(pt),
                    value: pt
                  }))}
                  value={this.state.receiver}
                  onChange={event =>
                    this.setFilter('receiver', event ? event.value : null)
                  }
                />
              </FormGroup>
            </Col>

            <Col>
              <FormGroup>
                <Label className={'w-100'}>
                  <Trans>Price</Trans>
                </Label>
                <Switch
                  bsSize={'normal'}
                  onText={'TTC'}
                  offText={'HT'}
                  onColor={'primary'}
                  offColor={'primary'}
                  value={ttc}
                  onChange={(el, state) => this.setFilter('ttc', state)}
                />
              </FormGroup>
            </Col>
          </Row>
          {url ? (
            <iframe
              title={filename}
              className="pdf-holder"
              src={url}
              onLoad={() => this.setState(ps => ({ ...ps, loading: false }))}
            />
          ) : null}
        </ModalBody>
      </Modal>
    );
  }
}

export default connect()(translate('translations-fr')(PlanModal));
