import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Trans, translate } from 'react-i18next';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
  Col
} from 'reactstrap';
import { AlertConfirm, Spinner, CustomGallery } from '../../../components';
import { cloneDeep, isEqual } from 'lodash';
import {
  addMedia,
  loadMedias,
  removeMedias,
  updateMedias
} from '../../../helpers/actions/projects';
import moment from 'moment';
import { mediasLabels } from '../../../helpers/nomenclators';
import { notify } from '../../../helpers/actions/index';

const grades = ['90'];

class Medias extends Component {
  constructor(props) {
    super(props);

    this.state = {
      medias: [],
      loading: true,
      uploading: [],
      adding: false,
      mediasDelete: false,
      indexSelected: 0
    };
  }

  componentWillMount() {
    this.load();
  }

  buildMedias(images) {
    let medias = images.map(media => {
      return {
        id: media._id,
        valide: media.valide,
        label: media.label ? media.label : '',
        name: media.name,
        src:
          media.source.toString() +
          '?v=' +
          moment(media.updated_at)
            .unix()
            .toString(),
        points: media.points,
        elements: media.elements,
        isSelected: false
      };
    });
    return medias;
  }

  load() {
    this.setState(ps => ({ ...ps, loading: true }));
    let { dispatch, projectId } = this.props;

    Promise.all([dispatch(loadMedias(projectId))])
      .then(([response]) => {
        let medias = this.buildMedias(response);
        this.setState(ps => ({ ...ps, medias, loading: false }));
      })
      .catch(() => {
        this.setState(ps => ({ ...ps, loading: false }));
      });
  }

  uploadClick() {
    let input = document.getElementById('uploadMedia');
    input.click();
  }

  /* Resize image helper */
  resizeImage = function(settings) {
    let file = settings.file;
    let maxSize = settings.maxSize;
    let maxBytes = settings.maxBytes;
    let reader = new FileReader();
    let image = new Image();
    let reduce = 200;
    let canvas = document.createElement('canvas');

    let dataURItoBlob = function(dataURI) {
      let bytes =
        dataURI.split(',')[0].indexOf('base64') >= 0
          ? atob(dataURI.split(',')[1])
          : unescape(dataURI.split(',')[1]);
      let mime = dataURI
        .split(',')[0]
        .split(':')[1]
        .split(';')[0];
      let max = bytes.length;
      let ia = new Uint8Array(max);
      for (let i = 0; i < max; i++) ia[i] = bytes.charCodeAt(i);
      let i = new Blob([ia], { type: mime });
      i['name'] = file.name;
      return new File([i], file.name);
    };

    let resize = function() {
      let width = image.width;
      let height = image.height;
      maxSize = width >= height ? width : height;
      maxSize = maxSize - reduce;
      reduce += 150;
      if (width > height) {
        if (width > maxSize) {
          height *= maxSize / width;
          width = maxSize;
        }
      } else {
        if (height > maxSize) {
          width *= maxSize / height;
          height = maxSize;
        }
      }
      canvas.width = width;
      canvas.height = height;
      canvas.getContext('2d').drawImage(image, 0, 0, width, height);
      let dataUrl = canvas.toDataURL('image/jpeg');
      let f = dataURItoBlob(dataUrl);
      // only if image exceed settings size resize again
      if (f.size > maxBytes) {
        return resize();
      } else {
        return f;
      }
    };

    return new Promise(function(ok, no) {
      if (!file.type.match(/image.*/)) {
        no(new Error('Not an image'));
        return;
      }
      reader.onload = function(readerEvent) {
        image.onload = function() {
          return ok(resize());
        };
        image.src = readerEvent.target.result;
      };
      reader.readAsDataURL(file);
    });
  };

  imageChange(e) {
    e.preventDefault();
    this.setState({ loading: true });

    let { dispatch, projectId, t } = this.props;
    let files = e.target.files;
    let maxBytes = 20000000;

    let promises = [];

    if (files.length > 5) {
      this.setState({ loading: false });
      return dispatch(
        notify('danger', t('Is not possible select more than 5 files'))
      );
    }

    for (let x = 0; x < files.length; x++) {
      maxBytes -= files[x].size;
    }

    if (maxBytes < 0) {
      this.setState({ loading: false });
      return dispatch(
        notify(
          'danger',
          t('Is not possible upload more than 20 megabytes in all your files')
        )
      );
    }

    Promise.all(promises)
      .then(() => {
        dispatch(addMedia(projectId, files))
          .then(() => this.load())
          .catch(() => this.setState({ loading: false }));
      })
      .catch(() => this.setState({ loading: false }));
  }

  selectImage(id) {
    let { medias } = this.state;
    let index = medias.findIndex(m => m.id === id);
    medias[index].isSelected = !medias[index].isSelected;
    this.setState(ps => ({ ...ps, medias }));
  }

  deleteImages() {
    this.setState({ loading: true });

    let { dispatch, projectId } = this.props;
    let { medias } = this.state;
    let entities = [];

    cloneDeep(medias).forEach(media => {
      if (media.isSelected) {
        entities.push(media.id);
      }
    });

    if (entities.length) {
      dispatch(removeMedias(projectId, entities))
        .then(() => this.load())
        .catch(() => this.setState({ loading: false }));
    } else {
      this.setState({ loading: false });
    }
  }

  validate() {
    this.setState({ loading: true });

    let { dispatch, projectId } = this.props;
    let { medias } = this.state;
    let entities = [];

    cloneDeep(medias).forEach(media => {
      if (media.isSelected) {
        media.valide = !media.valide;
        entities.push(media);
      }
    });

    if (entities.length) {
      dispatch(updateMedias(projectId, entities))
        .then(() => this.load())
        .catch(() => this.setState({ loading: false }));
    } else {
      this.setState({ loading: false });
    }
  }

  tag(value) {
    this.setState(ps => ({ ...ps, loading: true }));
    let { dispatch, projectId } = this.props;
    let { medias } = this.state;
    let entities = [];

    cloneDeep(medias).forEach(media => {
      if (media.isSelected) {
        media.label = value;
        entities.push(media);
      }
    });

    if (entities.length) {
      dispatch(updateMedias(projectId, entities))
        .then(() => this.load())
        .catch(() => this.setState({ loading: false }));
    } else {
      this.setState({ loading: false });
    }
  }

  rotate(grades, direction) {
    this.setState(ps => ({ ...ps, loading: true }));
    let { dispatch, projectId } = this.props;
    let { medias } = this.state;
    let entities = [];

    cloneDeep(medias).forEach(media => {
      if (media.isSelected) {
        media.grades = parseInt(grades);
        media.direction = direction;
        entities.push(media);
      }
    });

    if (entities.length) {
      dispatch(updateMedias(projectId, entities))
        .then(() => this.load())
        .catch(() => this.setState(ps => ({ ...ps, loading: false })));
    } else {
      this.setState(ps => ({ ...ps, loading: false }));
    }
  }

  openImage(currentImage) {
    let url = currentImage.src;
    let win = window.open(url, '_blank');
    win.focus();
  }

  updateImages(images) {
    let { dispatch, projectId } = this.props;
    let { medias } = this.state;
    let entities = [];

    cloneDeep(medias).forEach(m => {
      let image = images.find(i => i.id === m.id);
      if (image && !isEqual(image.elements, m.elements)) {
        m.elements = image.elements;
        entities.push(m);
      }
    });

    if (entities.length) {
      dispatch(updateMedias(projectId, entities))
        .then(() => {})
        .catch(err => {
          console.log('Error un medias update', err);
        });
    }
  }

  updateMedias(images) {
    this.setState(ps => ({ ...ps, loading: true }));
    let { dispatch, projectId } = this.props;
    let { medias } = this.state;
    let entities = [];

    cloneDeep(medias).forEach(m => {
      let image = images.find(i => i.id === m.id);
      if (image && !isEqual(image.points, m.points)) {
        m.points = image.points;
        entities.push(m);
      }
    });

    if (entities.length) {
      dispatch(updateMedias(projectId, entities))
        .then(() => this.load())
        .catch(() => this.setState({ loading: false }));
    } else {
      this.setState(ps => ({ ...ps, loading: false }));
    }
  }

  showLabel(index) {
    if (this.state.indexSelected === index) index = -1;
    this.setState(ps => ({ ...ps, indexSelected: index }));
  }

  render() {
    let { loading, medias, mediasDelete, indexSelected } = this.state,
      { t, created_at } = this.props;
    let imagesByLabel = [];
    mediasLabels.forEach(l => {
      imagesByLabel[l.toString()] = [];
    });
    medias.forEach(m => {
      if (imagesByLabel[m.label]) imagesByLabel[m.label].push(m);
      else imagesByLabel[m.label] = [m];
    });
    let keys = Object.keys(imagesByLabel).filter(
      l => imagesByLabel[l].length > 0
    );

    let newGallery = moment(created_at).isAfter('2019-09-15');

    return (
      <div>
        <Card className={'card-plain'} style={{ position: 'relative' }}>
          {loading ? <Spinner inside={true} /> : null}
          <CardHeader className={'pt-0 text-right'}>
            <input
              type="file"
              id="uploadMedia"
              name="uploadMedia"
              accept="image/png, image/jpeg"
              multiple={true}
              hidden={true}
              onChange={event => this.imageChange(event)}
            />
            <UncontrolledDropdown key={`grades-list`}>
              <DropdownToggle color="info" className="ml-1 btn-sm" caret>
                <Trans>Rotate</Trans>
              </DropdownToggle>
              <DropdownMenu right={true}>
                {grades.map(g => (
                  <DropdownItem
                    key={'grades-' + g}
                    onClick={() => this.rotate(g, 'right')}
                  >
                    <Trans>Right</Trans> {g}°
                    <i className={`float-right fa fa-lg fa-rotate-right`} />
                  </DropdownItem>
                ))}
                {grades.map(g => (
                  <DropdownItem
                    key={'grades-' + g}
                    onClick={() => this.rotate(g, 'left')}
                  >
                    <Trans>Left</Trans> {g}°
                    <i className={`float-right fa fa-lg fa-rotate-left`} />
                  </DropdownItem>
                ))}
                <DropdownItem
                  key={'grades-180'}
                  onClick={() => this.rotate(180, 'left')}
                >
                  <Trans>Rotate</Trans> 180°
                  <i className={`float-right fa fa-lg fa-refresh`} />
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
            <UncontrolledDropdown key={`label-list`}>
              <DropdownToggle color="info" className="ml-1 btn-sm" caret>
                <Trans>Labels</Trans>
              </DropdownToggle>
              <DropdownMenu className={'medias_label'} right={true}>
                {mediasLabels.map(l => (
                  <DropdownItem key={'label-' + l} onClick={() => this.tag(l)}>
                    {t(l)}
                  </DropdownItem>
                ))}
              </DropdownMenu>
            </UncontrolledDropdown>
            <Button size="sm" color="info" onClick={() => this.uploadClick()}>
              <Trans>Add</Trans>
            </Button>
            <Button size="sm" color="info" onClick={() => this.validate()}>
              <Trans>Validate</Trans>
            </Button>
            <Button
              size="sm"
              color="danger"
              onClick={() => this.setState({ mediasDelete: true })}
            >
              <Trans>Delete</Trans>
            </Button>
            <Button
              className={'ml-2'}
              size="sm"
              color={'default'}
              onClick={() => this.load()}
            >
              <i className="now-ui-icons arrows-1_refresh-69" />
            </Button>
          </CardHeader>
          <CardBody>
            {keys.length > 0 ? (
              keys.map((label, index) => {
                if (imagesByLabel[label] && imagesByLabel[label].length)
                  return (
                    <Col
                      xs={12}
                      key={'medias-label-' + label}
                      className={`m-0 p-0 mb-4`}
                    >
                      <h6
                        style={{
                          borderBottom: '2px solid lightgray',
                          cursor: 'pointer'
                        }}
                        className={'d-inline-block mb-3 w-100 pb-2'}
                        onClick={() => this.showLabel(index)}
                      >
                        {t(label)}
                        <div className={'float-right mr-2'}>
                          <i
                            className={`fas fa-lg ${
                              indexSelected === index
                                ? 'fa-chevron-down'
                                : 'fa-chevron-up'
                            }`}
                          />
                        </div>
                      </h6>
                      <div
                        className={`${
                          indexSelected === index ? 'd-inline-block' : 'd-none'
                        } w-100`}
                      >
                        <CustomGallery
                          key={'gallery-label-' + label}
                          images={cloneDeep(imagesByLabel[label])}
                          updateImages={images => this.updateImages(images)}
                          updateMedias={images => this.updateMedias(images)}
                          selectImage={id => this.selectImage(id)}
                          newGallery={newGallery}
                        />
                      </div>
                    </Col>
                  );
                return null;
              })
            ) : (
              <p className={'text-not-found text-center'}>
                <Trans>No medias found</Trans>
              </p>
            )}
          </CardBody>
        </Card>

        {mediasDelete ? (
          <AlertConfirm
            message={'The medias cannot be recovered'}
            onCancel={() => this.setState({ mediasDelete: false })}
            onConfirm={() =>
              this.setState({ mediasDelete: false }, () => this.deleteImages())
            }
          />
        ) : null}
      </div>
    );
  }
}

export default connect()(translate('translations-fr')(Medias));
