import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Trans, translate } from 'react-i18next';
import { Col, Row } from 'reactstrap';
import ListPayments from './ListPayments';
import { moneyFormatter } from '../../../helpers/formatters';

class BlockPayments extends Component {
  constructor(props) {
    super(props);
    this.state = {
      payments: [],
      title: '',
      entity: ''
    };
  }

  componentWillMount() {
    let { payments, title, entity } = this.props;
    this.setState(ps => ({
      ...ps,
      payments,
      title,
      entity
    }));
  }

  componentWillReceiveProps(nextProps, prevProps) {
    this.setState(ps => ({
      ...ps,
      payments: nextProps.payments,
      title: nextProps.title,
      entity: nextProps.entity
    }));
  }

  render() {
    let { title, entity, payments } = this.state,
      { t } = this.props;
    let debitsValue = 0;
    let creditsValue = 0;

    if (!this.props.show) {
      return (
        <Col xs={12} sm={6} md={6} lg={3} className={'mt-2'}>
          <h5 className={'m-0 mb-3 text-center'}>
            {t(title)} {this.props.percent ? `(${this.props.percent}%)` : null}
          </h5>
          <Row>
            <Col>
              <h6 className={'text-center'}>
                <Trans>User not assigned</Trans>
              </h6>
            </Col>
          </Row>
        </Col>
      );
    }

    payments.forEach(p => {
      if (p.entity === entity && p.type === 'debit') debitsValue += p.value;
      if (p.entity === entity && p.type === 'credit') creditsValue += p.value;
    });

    let total =
      entity === 'client'
        ? debitsValue - creditsValue
        : creditsValue - debitsValue;

    let ht = entity === 'pro' ? total - total * 0.1 : null;

    let debisPayments = payments.filter(
      p => p.entity === entity && p.type === 'debit'
    );

    let creditsPayment = payments.filter(
      p => p.entity === entity && p.type === 'credit'
    );

    return (
      <Col xs={12} sm={6} md={6} lg={3} className={'mt-2'}>
        <h5 className={'m-0 mb-3 text-center'}>
          {t(title)}
          {this.props.percent ? `(${this.props.percent}%)` : null}
        </h5>

        <Row>
          <Col>
            <h6 className={'m-0'}>
              {this.props.firstCredit ? t('Credits') : t('Debits')}
            </h6>
          </Col>
        </Row>
        <hr className={'mt-2 mb-1'} />
        <ListPayments
          createBill={id => this.props.createBill(id)}
          removePayment={id => this.props.removePayment(id)}
          updatePayment={id => this.props.updatePayment(id)}
          payments={this.props.firstCredit ? creditsPayment : debisPayments}
        />

        <Row>
          <Col>
            <h6 className={'m-0 mt-2'}>
              {this.props.firstCredit ? t('Debits') : t('Credits')}
            </h6>
          </Col>
        </Row>
        <hr className={'mt-2 mb-1'} />
        <ListPayments
          createBill={id => this.props.createBill(id)}
          removePayment={id => this.props.removePayment(id)}
          updatePayment={id => this.props.updatePayment(id)}
          payments={this.props.firstCredit ? debisPayments : creditsPayment}
        />
        {creditsPayment.length ? (
          <hr className={'mt-2 mb-2'} />
        ) : (
          <div className={'mt-2 mb-2'} />
        )}
        <Row>
          <Col xs={6} sm={6}>
            <h6>
              <Trans>Total</Trans>
            </h6>
            {ht !== null ? (
              <h6>
                <Trans>HT</Trans>
              </h6>
            ) : null}
          </Col>
          <Col>
            <h6 className={'pl-2'}>{moneyFormatter(total)}</h6>
            {ht !== null ? (
              <h6 className={'pl-2'}>{moneyFormatter(ht)}</h6>
            ) : null}
          </Col>
        </Row>
      </Col>
    );
  }
}

export default connect()(translate('translations-fr')(BlockPayments));
