import React, { Component } from 'react';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import { PanelHeader, Spinner } from '../../../components';
import { one } from '../../../helpers/actions/projects';
import NavbarProject from '../NavbarActions/NavbarProject';
import DetailsProject from './DetailsProject';
import DetailsCompound from './DetailsCompound';
import { setCookie } from '../../../helpers/cookies';
import queryString from 'query-string';

class Details extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      project: {},
      params: {}
    };
  }

  getProject(projectId) {
    this.setState({ loading: true });
    let { dispatch } = this.props;

    dispatch(
      one(projectId, {
        include: [
          'client',
          'pro',
          'supervisors',
          'sellers',
          'provider',
          'devis',
          'devisBase'
        ],
        fields: [
          'client',
          'type',
          'pro',
          'supervisors',
          'sellers',
          'provider',
          'devis',
          'devisBase',
          'name',
          'status',
          'documents',
          'address',
          'children',
          'parent',
          'plans',
          'proEvaluation',
          'created_at'
        ]
      })
    )
      .then(project => {
        let params = queryString.parse(this.props.location.search);
        this.setState({ project, loading: false, params });
      })
      .catch(() => this.setState({ loading: false }));
  }

  componentDidMount() {
    let { match } = this.props;
    this.getProject(match.params.id);
  }

  componentWillUnmount() {
    setCookie(`project_admin_${this.props.match.params.id}`, Date.now());
  }

  componentWillReceiveProps(nextProps, prevState) {
    let { project } = this.state;
    if (project._id !== nextProps.match.params.id)
      this.getProject(nextProps.match.params.id);
  }

  render() {
    let { loading, project, params } = this.state;

    return (
      <div>
        <PanelHeader size="sm" />
        <div className="content" style={{ position: 'relative' }}>
          {loading ? <Spinner /> : null}
          <NavbarProject project={project} history={this.props.history} />

          {project && project._id ? (
            project.type && project.type === 'compound' ? (
              <DetailsCompound
                project={project}
                params={params}
                reload={() => this.getProject(project._id)}
              />
            ) : (
              <DetailsProject
                project={project}
                params={params}
                reload={() => this.getProject(project._id)}
              />
            )
          ) : null}
        </div>
      </div>
    );
  }
}

export default connect()(translate('translations-fr')(Details));
